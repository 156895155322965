import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    events: [],
    loading: false,
};

const reducer = (state=initialState,action) => {

    switch (action.type){
     
        case actionTypes.ADD_EVENT_START:
            return updateObject(state,{loading:true});
    
        case actionTypes.ADD_EVENT_SUCCESS:
            const newItem = updateObject(action.eventData,
                {id: action.eventId});

            return updateObject(state,{  
            loading: false,
            events: state.events.concat(newItem)
            }
        );
      
        case actionTypes.ADD_EVENT_FAIL:
            return updateObject(state,{loading:false});


        case actionTypes.DELETE_EVENT_START:
            return updateObject(state,{loading:true});
    
        case actionTypes.DELETE_EVENT_SUCCESS:
        
            const updatedEvents= state.events.filter((element, _) =>  element.id !== action.eventData);

            return updateObject(state,{  
                loading: false,
                events: updatedEvents
            }
        );
      

        case actionTypes.DELETE_EVENT_FAIL:
            return updateObject(state,{loading:false});

        case actionTypes.FETCH_EVENTS_START:
            return updateObject(state,{loading:true});
        
        case actionTypes.FETCH_EVENTS_SUCCESS:
            return updateObject(state,{ events: action.events,loading:false});

        
        case actionTypes.FETCH_EVENTS_FAIL:
            return updateObject(state,{loading:false});
        
        default:
            return state;
    }
};

export default reducer;