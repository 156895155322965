import React from 'react';
import LogoWethink from '../../images/logo/logoWethink.png';

import classes from './Logo.css';

const logo = (props) => {
    return (
        <div >
            <img src={LogoWethink} alt="Logo_Wethink"/>
        </div>
    );
};

export default logo;