import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    news: [],
    loading: false,
};

const reducer = (state=initialState,action) => {

    switch (action.type){
     
        case actionTypes.ADD_NEWS_ITEM_START:
            return updateObject(state,{loading:true});
    
        case actionTypes.ADD_NEWS_ITEM_SUCCESS:
            const newItem = updateObject(action.newsData,
                {id: action.newsId});

            return updateObject(state,{  
            loading: false,
            news: state.news.concat(newItem)
            }
        );
      
        case actionTypes.ADD_NEWS_ITEM_FAIL:
            return updateObject(state,{loading:false});


        case actionTypes.DELETE_NEWS_ITEM_START:
            return updateObject(state,{loading:true});
    
        case actionTypes.DELETE_NEWS_ITEM_SUCCESS:
        
            const updatedNews= state.news.filter((element, _) =>  element.id !== action.newsData);
            console.log(updatedNews);

            return updateObject(state,{  
                loading: false,
                news: updatedNews
            }
        );
      

        case actionTypes.DELETE_NEWS_ITEM_FAIL:
            return updateObject(state,{loading:false});

        case actionTypes.FETCH_NEWS_START:
            return updateObject(state,{loading:true});
        
        case actionTypes.FETCH_NEWS_SUCCESS:
            return updateObject(state,{ news: action.news,loading:false});

        
        case actionTypes.FETCH_NEWS_FAIL:
            return updateObject(state,{loading:false});
        
        default:
            return state;
    }
};

export default reducer;