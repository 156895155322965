import React, {Component} from 'react';

import NavigationItem from '../NavigationItems/NavigationItem/NavigationItem';
import Logo from '../../Logo/Logo';

import NavigationItems from '../NavigationItems/NavigationItems';
import {updateObject} from '../../../shared/utility';
import LanguageToggle from '../../Localization/Localization';
import MobileLanguageToggle from '../../Localization/MobileLocalization';


class Header extends Component{

    state = {
             toggleMenuClasses: "toggle-menu",
             siteInfoWrapClasses: "site-info-wrap",
             iMenuClasses: "zmdi zmdi-menu",
             siteInfoWrapDisplay: {"display":"none"},
             ulStyle: {"display":"none"},

        }

 toggleMenuHandler = () => {
    let newState ={};

    if (this.state.toggleMenuClasses==="toggle-menu"){
         newState = {
            toggleMenuClasses: "toggle-menu active",
             siteInfoWrapClasses: "site-info-wrap active",
             siteInfoWrapDisplay: {"display":"block"},
             iMenuClasses: "zmdi zmdi-close"
        }
    }
    
         else {
            newState = {
                toggleMenuClasses: "toggle-menu",
                 siteInfoWrapClasses: "site-info-wrap",
                 siteInfoWrapDisplay: {"display":"none"},
                 iMenuClasses: "zmdi zmdi-menu",
            }
        }
        
        this.setState(updateObject(this.state,newState))


} 


toggleMobileMenuHandler = () => {
    if (this.state.ulStyle.display === "none"){
    this.setState(updateObject(this.state,{ulStyle:{"display":"block"} }))}
    else{
        this.setState(updateObject(this.state,{ulStyle:{"display":"none"} }))
    }
} 
  
  render() {
      
    return (
        <header id="header" className="htc-header">
        {/* <!-- Start Header Top   --> */}
        <div className="header__top bg-cat-1">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                        <div className="logo first-logo">
                            <NavigationItem link="/" exact active="active"><Logo/></NavigationItem>
                        </div>
                        
                    </div> 
                    {/* <h2 className='first-title'>Wethink.info</h2> */}
                </div>
            </div>                
        </div>
        {/* <!-- End Header Top -->
        <!-- Start Mainmenu Area --> */}
        <div id="sticky-header-with-topbar" className={this.props.stickyHeaderClasses} onScroll={this.onScrollHandler}>
            <div className="htc__mainmenu">
                <div className="container">
                    <div className="row">
                        <div className="header__wrap">
                            <div className="col-md-10 col-lg-10 col-sm-10">
                                <div className="mainmenu-content">
                                    <nav className="mainmenu__nav">
                                        <ul className="main__menu">
                                                 <NavigationItems isAuthenticated={this.props.isAuth}/>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-md-2 col-lg-2 col-sm-2">
                                <div className="htc__toggle__search">
                                     
                                   {/* <!-- Start Toggle Menu --> */}
                                    <div className="visible-mobile-menu-off">
                                        <div className={this.state.toggleMenuClasses} onClick={this.toggleMenuHandler}><i className={this.state.iMenuClasses}></i></div>
                                        <div className={this.state.siteInfoWrapClasses} style={this.state.siteInfoWrapDisplay}>
                                            <div className="li-col-full">
                                                <span className="close icon-clear pull-right" onClick={this.toggleMenuHandler}><i className="zmdi zmdi-close"></i></span>
                                                <em className="color main-font">Welcome To Wethink!</em>
                                            </div>
                                            <div className="toggle-wrap"> 
                                                {/* <!-- Start Single Menu --> */}
                                                <ul className="toggle-dropdown-menu ">
                                                    <li><h4 className="megamenu__subtitle"><span>My Account</span></h4></li>
                                                    <li><NavigationItem link="#" exact>What we do</NavigationItem></li>
                                                    <li><NavigationItem link="#" exact>Help</NavigationItem></li>
                                                    <li><NavigationItem link="#" exact >Jobs</NavigationItem></li>
                                                </ul>
                                                {/* <!-- End Single Menu -->
                                                <!-- Start Single Menu --> */}
                                                <ul className="toggle-dropdown-menu ">
                                                    <li><h4 className="megamenu__subtitle"><span>Language</span></h4></li>
                                                    <LanguageToggle/>
                                                </ul>
                                                {/* <!-- End Single Menu -->*/}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Toggle Menu --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Mainmenu Area -->
        <!-- Mobile-menu-area start --> */}
        
        <div className="mobile-menu-area hidden-md hidden-lg hidden-sm mean-container">
            <div className="mean-bar">
                <a   href="#nav" className="meanmenu-reveal"
                     onClick={this.toggleMobileMenuHandler}
                     style={{background:"",color:"",right:"0",left:"auto"}}>
                      <i class="zmdi zmdi-menu" style={{float:'right'}}></i>
                </a>
                <nav className="mean-nav">
                      
                            <ul style={this.state.ulStyle}>
                               <MobileLanguageToggle/>
                                <NavigationItems isAuthenticated={this.props.isAuth}/>
                            </ul>
                </nav>
            </div>
            <div className="fluid-container mobile-menu-container">
               {/*  <div className="mobile-logo"><NavigationItem link="/" exact><Logo/></NavigationItem></div> */}
        
                </div>
            </div>
        {/* <!-- Mobile-menu-area End --> */}
    </header>
    );}


}
export default Header;