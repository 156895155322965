import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';
import firebase from "firebase";

const config = {
    apiKey: "AIzaSyAGxKHe3tt3mm8y8wxomQGPQbio-kekBf4",
    authDomain: "lalaws-7186d.firebaseapp.com",
    storageBucket: "lalaws-7186d.appspot.com",
  };
  firebase.initializeApp(config);

export const addNewsItemSuccess = (id, newsData) => {
    return {
        type: actionTypes.ADD_NEWS_ITEM_SUCCESS,
        newsId: id,
        newsData: newsData,
    }
}


export const addNewsItemFail = (error) => {
    return {
        type: actionTypes.ADD_NEWS_ITEM_FAIL,
        error: error
    }
}

export const addNewsItemStart = () => {
    return {
        type: actionTypes.ADD_NEWS_ITEM_START
    };
};


export const addNewsItem = (news,token) => {
    return dispatch => {
        dispatch(addNewsItemStart());
        
        
        
        const storageRef = firebase.storage().ref("news");
        const imageRef = storageRef.child(news.image.name);
        imageRef.put(news.image)
                .then((snapshot)=> {
                    snapshot.ref.getDownloadURL()
                    .then((url) =>  {
                        console.log('[URL LRU]', url)
                        const newsMeta = {
                                content: news.content, 
                                title: news.title,
                                userId: news.userId, 
                                imageName: "news/"+news.image.name,
                                imageURL: url+"?alt=media"} 
    
                        axios.post('/news.json?auth='+token,newsMeta)
                        .then(response =>{
                            console.log(response)    
                            dispatch(addNewsItemSuccess(response.data.name,newsMeta)
                        )  
                        })
                        .catch(error => {
                             dispatch(addNewsItemFail(error));
                        });
    
                         }
                        )
                    .catch(error => dispatch(addNewsItemFail(error)))

                })
                .catch(error => {dispatch(addNewsItemFail(error));})

        

      
    }
}

export const addNewsItemInit = () =>{
    return {
        type: actionTypes.ADD_NEWS_ITEM_INIT
    };
};


export const deleteNewsItemSuccess = (newsData) => {
    return {
        type: actionTypes.DELETE_NEWS_ITEM_SUCCESS,
        newsData: newsData
    }
}


export const deleteNewsItemFail = (error) => {
    return {
        type: actionTypes.DELETE_NEWS_ITEM_FAIL,
        error: error
    }
}

export const deleteNewsItemStart = () => {
    return {
        type: actionTypes.DELETE_NEWS_ITEM_START
    };
};

export const deleteNewsItem = (token,news) => {
    return dispatch => {
        dispatch(deleteNewsItemStart());
        axios.delete('/news/'+news+'.json?auth='+token)
           .then(response =>{

                dispatch(deleteNewsItemSuccess(news))
            })
           .catch(error => {
               console.log(error)
                dispatch(deleteNewsItemFail(error));
           });
    }
}

export const deleteNewsItemInit = () =>{
    return {
        type: actionTypes.DELETE_NEWS_ITEM_INIT
    };
};




export const fetchNewsSuccess = (news) =>{
    return {
        type: actionTypes.FETCH_NEWS_SUCCESS,
        news: news
    };
};

export const fetchNewsFail = (error) =>{
    return {
        type: actionTypes.FETCH_NEWS_FAIL,
        error: error
    };
};


export const fetchNewsStart = () =>{
    return {
        type: actionTypes.FETCH_NEWS_START
    };
};


export const fetchNews = (token) => {
    return dispatch => {
        dispatch(fetchNewsStart());
    
        axios.get('/news.json' )
        .then(res =>{
                const fetchedNews=[];
                for (let key in res.data){
                   
                        fetchedNews.push({
                        ...res.data[key],
                        id: key,
                    });
                }
                  
                dispatch(fetchNewsSuccess(fetchedNews));
        })
        .catch(error => {
            dispatch(fetchNewsFail(error));
        });
    }
}