import React, { Component } from 'react'

 class Scrollup extends Component {
 state = {
     display: {"position": "fixed",
                "zIndex": "2147483647", 
                "display": "block"}
 }

    render() {

    return (
        <a id="scrollUp" href="#top" style= {this.state.display}>
            <i className="zmdi zmdi-chevron-up"></i>
        </a>
    )
  }
}

export default Scrollup;