import React, { Component } from 'react';

//import Organigram from '../Organigram/Organigram';
import FirstVideo from '../../components/FirstVideo/FirstVideo';
//import AboutUs from '../../components/AboutUs/AboutUs';
import Services from '../../components/Services/Services';
//import Story from '../../components/Story/Story';
//import Numbers from '../../components/Numbers/Numbers';
//import Gallery from '../Gallery/Gallery';
//import Events from '../Events/Events';
//import Banner from '../Banner/Banner';
//import News from '../News/News';

import '../../css/bootstrap.min.css';
import '../../index.css';
import '../../css/core.css';
import '../../css/shortcode/shortcodes.css';
import '../../css/responsive.css';
import '../../css/custom.css';
import '../../css/plugins/jquery-ui.css';



class Presentation extends Component {

    render() {
      
    return (
      <div>
        <FirstVideo/>
        <Services/>
        {/* 
        <AboutUs/>
        <Organigram/>
        <Story/>
        <Numbers/>
        <Gallery/>
        <Events/>
        <Banner/>
         <News/>
         */}
      </div>
    )
  }
}

export default  Presentation;