import React , {Component} from 'react';
import {connect} from 'react-redux';

import { withLocalize } from "react-localize-redux";


import Header from '../../components/Navigation/Header/Header.js';
import Footer from '../../components/Navigation/Footer/Footer.js';
import Scrollup from '../../components/Navigation/Scrollup/Scrollup';


class  Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {scrolling: false,
            stickyHeaderClasses: "ml-header-top transparent--header hidden-xs"};
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        if (window.scrollY>200)
         this.setState({scrolling: true, stickyHeaderClasses: "ml-header-top transparent--header hidden-xs scroll-header"});
         else this.setState({scrolling: false,stickyHeaderClasses: "ml-header-top transparent--header hidden-xs"});
    }
   
    render (){
        return (
            <div className="wrapper" onScroll={this.handleScroll}>
                <Header isAuth= {this.props.isAuthenticated} stickyHeaderClasses={this.state.stickyHeaderClasses}/>
                     <main>{this.props.children}</main>
                <Footer/>
               {this.state.scrolling ? <Scrollup/>: null}
            </div>
            )
        }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !==null
    }
}
export default connect(mapStateToProps)(withLocalize(Layout));