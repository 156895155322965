import React from 'react';
import Logo from '../../Logo/Logo';
import NavigationItem from '../NavigationItems/NavigationItem/NavigationItem';

import { withLocalize, Translate } from 'react-localize-redux';

const Footer = () => {
    return (
        <footer className="htc__footer">
        <div className="footer__container bg-cat-3"> 
            <div className="container">
               { /* <!-- Start Footer widget Area --> */}
                <div className="row">
                    <div className="footer__widget__wrap clearfix">
                        {/* <!-- Start Single Widget --> */}
                        <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                            <div className="footer xs-mt-0">
                                <div className="footer__widget">
                                    <div className="ftr--logo">
                                    <NavigationItem link="/" exact>
                                          <Logo />
                                        </NavigationItem>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Single Widget --> */}
                       
                       {/* <!-- Start Single Widget --> */}
                       
                        <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                            <div className="footer" >
                            <center>
                                <div className="footer__widget">
                                    <h3 className="footer__title"><Translate id="navigationitems.content14"/></h3>
                                    <div className="footer__inner">
                                        <div className="ft__address">
                                            <p><span><Translate id="navigationitems.content15"/></span>
                                                <NavigationItem link="phone:+21652213640" exact>+(216) 52 213 640</NavigationItem>
                                            </p>
                                        </div>
                                        <div className="ft__address">
                                            <p><span><Translate id="navigationitems.content16"/></span>
                                                <NavigationItem link="mailto:www.yourmail.com" exact>contact@wethink.info</NavigationItem>
                                            </p>
                                        </div>
                                        <div className="ft__address">
                                            <p style={{color:'#cccccc'}}><span> <Translate id="navigationitems.content17"/> </span>
                                            <Translate id="navigationitems.content18"/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </center>
                            </div>
                        </div>
                        {/* <!-- End Single Widget --> */}
                    </div>
                </div>
                {/* <!-- End Footer widget Area --> */}
            </div>      
        </div>
        <div className="cpoyright bg-cat-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div className="cpoyright__content">
                            <div className="copyright__inner">
                                <p>
                                <Translate id="navigationitems.content19"/>
                                    <NavigationItem link="https://www.wethink.info">
                                    <Translate id="navigationitems.content20"/>
                                    </NavigationItem>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
};

export default withLocalize(Footer);