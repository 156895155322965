import React from 'react';
import { withLocalize } from 'react-localize-redux';


import en from '../../images/ser-img/1.png';
import fr from '../../images/ser-img/2.png';
import ar from '../../images/ser-img/3.png';
import Aux from '../../hoc/Aux/Aux';


const LanguageToggle = ({languages, activeLanguage, setActiveLanguage}) => {
let langImgSrc = ar;
let langs = languages.map(lang => {
  lang.code==="en"? langImgSrc = en : lang.code === "fr"? langImgSrc = fr : langImgSrc = ar
  return(
  <li  key={ lang.code } onClick={() => setActiveLanguage(lang.code)}  style={{ height: "50px"}}  >
    <b>{lang.name}</b>
    <span style={{ position: "relative", top: "-95px", left: "-25px", height:'30px'}} >
    
    {/*<img className="a" src={langImgSrc} alt="flag images" height='30px'></img> */}</span>
 
  </li>);}
)
  return (
    <Aux>
      {langs}
    </Aux>
  );
};

export default withLocalize(LanguageToggle);

