import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    photos: [],
    loading: false,
};

const reducer = (state=initialState,action) => {

    switch (action.type){
     
        case actionTypes.ADD_PHOTO_START:
            return updateObject(state,{loading:true});
    
        case actionTypes.ADD_PHOTO_SUCCESS:
            const photo = updateObject(action.photoData,
                {id: action.photoId});

            return updateObject(state,{  
            loading: false,
            photos: state.photos.concat(photo)
            }
        );
      
        case actionTypes.ADD_PHOTO_FAIL:
            return updateObject(state,{loading:false});


        case actionTypes.DELETE_PHOTO_START:
            return updateObject(state,{loading:true});
    
        case actionTypes.DELETE_PHOTO_SUCCESS:
        
            const updatedPhotos= state.photos.filter((element, _) =>  element.id !== action.photoData);
           

            return updateObject(state,{  
                loading: false,
                photos: updatedPhotos
            }
        );
      

        case actionTypes.DELETE_PHOTO_FAIL:
            return updateObject(state,{loading:false});

        case actionTypes.FETCH_PHOTOS_START:
            return updateObject(state,{loading:true});
        
        case actionTypes.FETCH_PHOTOS_SUCCESS:
            return updateObject(state,{ photos: action.photos,loading:false});

        
        case actionTypes.FETCH_PHOTOS_FAIL:
            return updateObject(state,{loading:false});
        
        default:
            return state;
    }
};

export default reducer;