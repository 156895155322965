import axios from 'axios';

import * as actionTypes from './actionTypes';


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};


export const authSuccess = (token, userId,role, name) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        role: role,
        userName: name
    };
};


export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('userName');
    return{
        type: actionTypes.AUTH_LOGOUT,
        role: "user"
    };
};

export const checkAuthTimeout = (expirationTime) => {

    return dispatch => {
        setTimeout(() => {
            dispatch (logout());
        },expirationTime * 1000);
    };

};

export const setAuthRedirectPath = (path) => {
    return{
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};


    


export const auth = (email, password, name, method) =>{
    return dispatch => {
        dispatch(authStart());
        let authData = {
            email:email,
            password: password,
            returnSecureToken: true
        }

        const signupURL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=AIzaSyAGxKHe3tt3mm8y8wxomQGPQbio-kekBf4';
        const siginURL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyAGxKHe3tt3mm8y8wxomQGPQbio-kekBf4';
        const accountInfoURL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key=AIzaSyAGxKHe3tt3mm8y8wxomQGPQbio-kekBf4';
        const userDataURL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=AIzaSyAGxKHe3tt3mm8y8wxomQGPQbio-kekBf4';

        let postURL = method === 'signup' ? signupURL :siginURL;
        let userName = name;
        axios.post(postURL,authData)
        .then(resp1 => {
            const expirationDate = new Date(new Date().getTime() + resp1.data.expiresIn * 1000);
            localStorage.setItem('token',resp1.data.idToken);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('userId', resp1.data.localId);

            const queryParams = '?auth=' + resp1.data.idToken ;
            
            if (method === 'signup'){
                authData ={
                    idToken: resp1.data.idToken,
                    displayName: name,
                    returnSecureToken: true
                }
                axios.post(accountInfoURL, authData)
                     .then(resp2 => {
                        localStorage.setItem('userName',name);
                                       
                        axios.get('https://lalaws-7186d.firebaseio.com/roles/'+resp1.data.localId+'.json' + queryParams)
                             .then(resp3 => {
                                 localStorage.setItem('role',resp3.data.role);
                                 let role = resp3.data.role;
                                 dispatch(authSuccess(resp1.data.idToken,resp1.data.localId, role,name));
                               })
                              .catch(error => {
                                   localStorage.setItem('role','user');
                                   let role = 'user';
                                    dispatch(authSuccess(resp1.data.idToken,resp1.data.localId, role , name));})
                                 }
                            )
                     .catch( error => {
                             dispatch(authFail(error.response.data.error));
                          })
            } else {
                const query = {idToken: resp1.data.idToken}
                axios.post(userDataURL,query)
                     .then(resp4 => { 
                            userName = resp4.data.users[0].displayName;
                            localStorage.setItem('userName',userName); 
                            axios.get('https://lalaws-7186d.firebaseio.com/roles/'+resp1.data.localId+'.json' + queryParams)
                                 .then(resp5 => {
                                             localStorage.setItem('role',resp5.data.role);
                                             let role = resp5.data.role;
                                             dispatch(authSuccess(resp1.data.idToken,resp1.data.localId, role,userName));
                                        })
                                 .catch(error => {
                                             localStorage.setItem('role','user');
                                             let role = 'user';
                                             dispatch(authSuccess(resp1.data.idToken,resp1.data.localId, role , userName));})
                                            })  
                        .catch(error => 
                        {   
    
                            dispatch(authFail(error.response.data.error))
                        })
                }
            
                dispatch(checkAuthTimeout(resp1.data.expiresIn));
               })
             .catch(error => {
                   dispatch(authFail(error.response.data.error));
             });

    };
};


export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token){
             dispatch(logout());
            } else{
                const expirationDate = new Date( localStorage.getItem('expirationDate'));
                if (expirationDate <= new Date()){ 
                    dispatch(logout());
                    } else {
                   
                    const userId = localStorage.getItem('userId');
                    const role = localStorage.getItem('role');
                    const userName = localStorage.getItem('userName');
                    dispatch(authSuccess(token,userId,role, userName));
                    dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())/1000))
                    }

        }   
    };
};