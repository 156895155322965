export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const FETCH_INGREDIENTS_FAILED = 'FETCH_INGREDIENTS_FAILED';

export const PURCHASE_BURGER_START = 'PURCHASE_BURGER_START';
export const PURCHASE_BURGER_SUCCESS = 'PURCHASE_BURGER_SUCCESS';
export const PURCHASE_BURGER_FAIL = 'PURCHASE_BURGER_FAIL';
export const PURCHASE_INIT = 'PURCHASE_INIT';

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const FETCH_NEWS_START = 'FETCH_NEWS_START';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAIL = 'FETCH_NEWS_FAIL';

export const ADD_NEWS_ITEM_START = 'ADD_NEWS_START';
export const ADD_NEWS_ITEM_SUCCESS = 'ADD_NEWS_SUCCESS';
export const ADD_NEWS_ITEM_FAIL = 'ADD_NEWS_FAIL';
export const ADD_NEWS_ITEM_INIT = 'ADD_NEWS_INIT';

export const DELETE_NEWS_ITEM_START = 'DELETE_NEWS_START';
export const DELETE_NEWS_ITEM_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_ITEM_FAIL = 'DELETE_NEWS_FAIL';
export const DELETE_NEWS_ITEM_INIT = 'DELETE_NEWS_INIT';


export const ADD_PHOTO_START = 'ADD_PHOTO_START';
export const ADD_PHOTO_SUCCESS = 'ADD_PHOTO_SUCCESS';
export const ADD_PHOTO_FAIL = 'ADD_PHOTO_FAIL';
export const ADD_PHOTO_INIT = 'ADD_PHOTO_INIT';

export const DELETE_PHOTO_START = 'DELETE_PHOTO_START';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAIL = 'DELETE_PHOTO_FAIL';
export const DELETE_PHOTO_INIT = 'DELETE_PHOTO_INIT';

export const FETCH_PHOTOS_START = 'FETCH_PHOTOS_START';
export const FETCH_PHOTOS_SUCCESS = 'FETCH_PHOTOS_SUCCESS';
export const FETCH_PHOTOS_FAIL = 'FETCH_PHOTOS_FAIL';


export const ADD_EVENT_START = 'ADD_EVENT_START';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAIL = 'ADD_EVENT_FAIL';
export const ADD_EVENT_INIT = 'ADD_EVENT_INIT';

export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';
export const DELETE_EVENT_INIT = 'DELETE_EVENT_INIT';

export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL';