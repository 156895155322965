import React from 'react';
import cloud from'../../images/iconServices/cloud.jpg';
import docker from '../../images/iconServices/docker.jpg';
import iot from '../../images/iconServices/iot.jpg';
import blockchain from '../../images/iconServices/blockchain.png';

import { withLocalize, Translate } from 'react-localize-redux';

const Services = () => {
    
    return (
        <section className="htc__service ptb-90 bg-white">
            <div className="container">
                {/* <!-- Start Section Title --> */}
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div className="section__title text-center">
                            <h2 className="title-line"><b><Translate id="navigationitems.content4"/></b></h2>
                            <p className="title-desc" style={{ fontSize: "20px" }}><Translate id="navigationitems.content5"/></p>
                        </div>
                    </div>
                </div>
                {/* <!-- End Section Title -->
                <!-- Start Service Area --> */}
                <div className="row iconServices">
                    <div className="service__container clearfix mt-50 sm-mt-20 xs-mt-20 icon">
                        {/* <!-- Start Single Service --> */}
                        
                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                            <div className="service text-center">
                                 <div className="service__icon">
                                 <img src={cloud} alt="cloud_logo" />
                                </div> 
                                <div className="service__details">
                                    <h4><a href="/#"><Translate id="navigationitems.content6" /></a></h4>
                                    <p><Translate id="navigationitems.content7" /> </p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Single Service -->
                        <!-- Start Single Service --> */}
                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                            <div className="service text-center">
                                <div className="service__icon">
                                    <img src={docker} alt="docker_logo" />
                                </div>
                                <div className="service__details">
                                    <h4><a href="/#"> <Translate id="navigationitems.content8"/> </a></h4>
                                    <p> <Translate id="navigationitems.content9"/> </p>
                                </div>
                            </div>
                        </div>
                        
                        {/* <!-- End Single Service -->*/}
                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                            <div className="service text-center">
                                <div className="service__icon">
                                    <img src={iot} alt="iot_logo" />
                                </div>
                                <div className="service__details">
                                    <h4><a href="/#"><Translate id="navigationitems.content10"/></a></h4>
                                    <p><Translate id="navigationitems.content11"/></p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Single Service -->
                        <!-- Start Single Service --> */}
                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                            <div className="service text-center">
                                <div className="service__icon">
                                    <img src={blockchain} alt="Blockchain_logo" />
                                </div>
                                <div className="service__details">
                                    <h4><a href="/#"><Translate id="navigationitems.content12"/></a></h4>
                                    <p><Translate id="navigationitems.content13"/></p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Single Service --> */}
                        
                    </div>

                    </div>

                {/* <!-- End Service Area --> */}
            </div>
        </section>
    );
};

export default withLocalize(Services);