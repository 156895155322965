import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';

import { withLocalize, Translate } from 'react-localize-redux';

import translations from '../../translations/navigationItems.json';
import { renderToStaticMarkup } from 'react-dom/server';

class FirstVideo extends Component {
    constructor (props) {
        super(props)
        this.state = {
            videoVisible :false
        }
        this.props.initialize({
            languages: [
              { name: 'English', code: 'en' },
              { name: 'Français', code: 'fr' }, 
              { name: 'العربية', code: 'ar' }
            ],
            translation: translations,
            options: { renderToStaticMarkup }
          });
        this.onPopupHandler = this.onPopupHandler.bind(this)
      }

    onPopupHandler = () => {
      this.setState({videoVisible: true}); 
    }

    onClickHandler = () => {
            
      this.setState({videoVisible: false});
    
    }
 
    render() {
    return (
        <div className="htc__slider__area">
        <div className="htc__sliders htc__sliders_activation  htc__sliders__navstyle--one">
            {/* <!-- Start slide --> */}
            <div className="htc__slider slider-bg-1 htc__slider__animation--center text-center" data-black-overlay="6">
                <div className="slider__container" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <div className="slider__wrap">
                                    <div className="slider__inner">
                                        <h1 className="text-theme wow"><Translate id="navigationitems.content1"/></h1>
                                        <p className="wow">
                                      <h2><Translate id="navigationitems.content2"/></h2><br></br><br></br> <h4><Translate
                                                  id="navigationitems.content3"
                                                /> </h4>
                                        </p>
                                       
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                 
                </div>
            </div>
            <ModalVideo channel='youtube' isOpen={this.state.videoVisible} videoId='_vdA11mgChY' onClose={() => this.setState({videoVisible: false})} />
            {/* <!-- End slide --> */}
        </div>
    </div>
    )
  }
}

export default withLocalize(FirstVideo);