import React ,{Component } from 'react';

import { renderToStaticMarkup } from 'react-dom/server';

import { withLocalize, Translate } from 'react-localize-redux';

import translations from '../../../translations/navigationItems.json';
import NavigationItem from './NavigationItem/NavigationItem';
import Aux from '../../../hoc/Aux/Aux';


class navigationItems extends Component {

   constructor(props) {
      super(props);
  

      this.props.initialize({
         languages: [
           { name: 'English', code: 'en' },
           { name: 'Français', code: 'fr' }, 
           { name: 'العربية', code: 'ar' }
         ],
         translation: translations,
         options: { renderToStaticMarkup }
       });
    }
   

   render(){
   return (
                                               <Aux>
                                                 <li>
                                                <NavigationItem link="/" exact>
                                                <Translate
                                                  id="navigationitems.presentation"
                                                />
                                              </NavigationItem>
                                              </li>
                                              
                                              <li>
                                              <NavigationItem link="/services" exact>
                                                <Translate
                                                  id="navigationitems.services"
                                                />  
                                              </NavigationItem>
                                              </li>
                                              
                                              <li>
                                               <NavigationItem link="/contactUs" exact >
                                                <Translate
                                                  id="navigationitems.contactUs"
                                                />  
                                              </NavigationItem>
                                              </li>
                                              
                                             
                                                {this.props.isAuthenticated?
                                              <li> <NavigationItem link="/" exact> 
                                                    <Translate style={{whiteSpace:"nowrap"}}
                                                  id="navigationitems.presentation"/>
                                              </NavigationItem></li>: null}
                                              <li>
                                                {!this.props.isAuthenticated ? 
                                              <NavigationItem link="/auth" exact>
                                                  <Translate
                                                  id="navigationitems.login"
                                                />  
                                              </NavigationItem> : 
                                              <NavigationItem link="/logout" exact>
                                                  <Translate
                                                  id="navigationitems.logout"
                                                />  
                                              </NavigationItem>
                                           
                                              }
                                                 </li>
                                          </Aux>
                                          
    );
      }
};






export default withLocalize(navigationItems);