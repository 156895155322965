import React from 'react';
import { withLocalize } from 'react-localize-redux';


import en from '../../images/ser-img/1.png';
import fr from '../../images/ser-img/2.png';
import ar from '../../images/ser-img/3.png';
import Aux from '../../hoc/Aux/Aux';
import NavigationItem from '../Navigation/NavigationItems/NavigationItem/NavigationItem';


const LanguageToggle = ({languages, activeLanguage, setActiveLanguage},props) => {

let langImgSrc = ar;
let langs = languages.map(lang => {
  lang.code==="en"? langImgSrc = en : lang.code === "fr"? langImgSrc = fr : langImgSrc = ar
  return(
   <span  key={ lang.code }  style={{float: "left", width: "20%"} }onClick={() => setActiveLanguage(lang.code)} className="a">
   <NavigationItem link="#" >
  
    <img onClick={() => setActiveLanguage(lang.code)}  src={langImgSrc} alt="flag images"></img>
   </NavigationItem></span>
   
  );}
)
  return (
    <Aux>
      {langs}
    </Aux>
  );
};

export default withLocalize(LanguageToggle);

