import React from 'react';
import {NavLink} from 'react-router-dom';

const navigationItem = (props) => {
    return (
      
            <NavLink className={props.classes}
                exact={props.exact}
                to={props.link}>
                {props.children}</NavLink>
        
    );
};

export default navigationItem;