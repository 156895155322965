import React, { Component } from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Layout from './hoc/Layout/Layout'

import Logout from './containers/Auth/Logout/Logout';
import * as actions from './store/actions/index';
import Presentation from './containers/Presentation/Presentation';

import './index.css';


const asyncAuth = asyncComponent(() => {
  return import('./containers/Auth/Auth');
});


const asyncServices = asyncComponent(() => {
  return import('./containers/Services/Services');
});

const asyncontactUs = asyncComponent(() => {
  return import('./containers/ContactUs/ContactUs');
});

class App extends Component {
  
  componentDidMount() {
    this.props.onTryAutoSignup();
  }
  render() {

    let routes = (
      <Switch>
          <Route path="/auth" component={asyncAuth}/>
          
          <Route path="/services" exact component={asyncServices}/>
          <Route path="/contactUs" exact component={asyncontactUs}/>
          <Route path="/" exact component={Presentation}/>
          <Redirect to='/'/>
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = 
      ( <Switch>
       
        <Route path="/services" exact component={asyncServices}/>
        <Route path="/contactUs" exact component={asyncontactUs}/>
        <Route path="/logout" component={Logout}/>
        <Route path="/" exact component={Presentation}/>
        <Redirect to='/'/>
      </Switch>);
    }
    return (
     
        <Layout>
          {routes}
        </Layout>
      
    );
  }
}


const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
