import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware,compose,combineReducers} from 'redux';
import thunk from 'redux-thunk';
import { LocalizeProvider, localizeReducer } from "react-localize-redux";


import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import burgerBuilderReducer from './store/reducers/burgerBuilder';

import authReducer from './store/reducers/auth';
import newsReducer from './store/reducers/news';
import photosReducer from './store/reducers/photos';
import eventsReducer from './store/reducers/events';

import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
//import "bootstrap-css-only/css/bootstrap.min.css";

const composeEnhancers = process.env.NODE_ENV === 'development'? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
    localize: localizeReducer,
    auth: authReducer,
    burgerBuilder: burgerBuilderReducer,
    events: eventsReducer,
    news: newsReducer,
    photos: photosReducer,
});

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
//const store = createStore(rootReducer, applyMiddleware(thunk));
const app = (
    <Provider store={store}>
        <LocalizeProvider store={store}>
           <BrowserRouter>
               <App/>
           </BrowserRouter>
        </LocalizeProvider>
    </Provider>
)
ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
